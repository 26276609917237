import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Securities Financing Transaction "
const pageTitle = "Securities Financing Transaction"
const subTitle = "Banking and Capital Markets"
const para = (
 ` <div>
    <h3>Business Problem</h3>
    <p>
      Build a System to report SFT’s such as securities lending, repos, buy-sell
      backs and margin lending and associated collateral to an EU registered
      repository. A securities financing transaction includes:
    </p>
    <ul className="features-list">
      <li>
        <Icon.Square />A repurchase transaction.
      </li>
      <li>
        <Icon.Square />
        Securities or commodities lending or borrowing.
      </li>
      <li>
        <Icon.Square />A buy-sell back transaction or sell-buy back transact.
      </li>
      <li>
        <Icon.Square /> Dedicated services interact with the Data Sources to
        fetch data from the data sources and version them.
      </li>
      <li>
        <Icon.Square />A margin lending transaction.
      </li>
    </ul>
  </div>`
)
const blockquote = `"Transaction Reporting for European Union regulation that aims to reduce systemic risk."`

const cardItems = [
  {
    id: 1,
    heading: `Business Solution`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Atyeti SFTR reporting implementation spans across Stock loans, Repo
          and Margin Lending asset classes
        </li>
        <li>
          <Icon.Square />
          As this is a bank wide regulation the solution included building out
          of a new Regulatory reporting engine (RRaaS).
        </li>
        <li>
          <Icon.Square />
          This was built across both the Banks IB and IWM divisions.
        </li>
      </>
    ),
  },

  {
    id: 2,
    heading: `Technical Solution`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Hybrid Cloud Data Sync: Sync between private cloud and Azure (feeds
          ingestion, data transfer, storage,validation).
        </li>
        <li>
          <Icon.Square />
          Data Processing: Auto-scaling cluster is used for batch processing
          utilizing Azure PaaS offerings.
        </li>
        <li>
          <Icon.Square />
          Identity Management: Implementation of Centrify, Azure AD, and Azure
          Key Vault.
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Result : Meeting Regulatory deadlines`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Atyeti SFTR solution is now processing full message volumes from all
          source systems.
        </li>
        <li>
          <Icon.Square />
          The Atyeti SFTR solution is now in production (Feeding direct to
          DTCC).
        </li>
        <li>
          <Icon.Square />
          The Atyeti SFTR reporting engine is processing full message volume
          from all source feeds.
        </li>
        <li>
          <Icon.Square />
          So far 500K+ messages are reported on a daily basis with a 93%+ ack
          rate with 13M messages in May 2020.
        </li>
      </>
    ),
  },
]
const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query SecuritiesTransformQuery {
    casedetailsJson(slug: { eq: "securities-financing-transaction" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
